import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { GetAyudasVisuales } from "../../../../hooks/getAyudasVisuales";
import { GetExistenciaIndividual } from "../../../../hooks/getExistenciasIndividuales";
import { ThemeContext } from "@emotion/react";
import { KeyboardArrowDown, KeyboardArrowUp, Search } from "@mui/icons-material";

const TablaGeneral = ({ sample, setAyudasVisuales, setOpenAyudas, almacen, filtro, handleInputChange, onPressEnter, canSearch}) => {
  const theme = useContext(ThemeContext);
  const rows = sample;
  const { getAyudasVisualesMutation } = GetAyudasVisuales();

  const VerSerial = ({data}) => {
    const seriales = data;

    const Title = () => {

      return (
        <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', flexDirection:'column'}}>
          {seriales.map(item => (
            <span>{item}</span>
          ))}
        </div>
      )
    }

    return(
      <Tooltip title={<Title />} placement="top" arrow>
        <Button variant="text">Ver Seriales</Button>
      </Tooltip>
    )
  }


  const Row = (props) => {
    const { row } = props;
    const {getExistenciaIndividualMutation} = GetExistenciaIndividual();
    const [open, setOpen] = useState(false);
    const [movimientos, setMovimientos] = useState([]);
    

    const getResources = (id) => {
      getAyudasVisualesMutation(id, {
        onSuccess: (data) => {
          setAyudasVisuales(data);
          setOpenAyudas(true);
        },
        onError: (error) => {
          console.log(error);
        },
      });
    };

    const get_movimientos = (id_parte) => {
      getExistenciaIndividualMutation({almacen: almacen, parte: id_parte},{
        onSuccess: (data) => {
          for(let mov of data){
            let seriales = new Set(mov?.moin_serial);
            mov.moin_serial = [...seriales]
          }
          setMovimientos(data);
        },
        onError: (error) => {
          console.log(error)
        }
      })
    }

    const open_subtabla = (id_parte) => {
      if(open){
        setOpen(!open);
        setMovimientos([]);
      }else{
        get_movimientos(id_parte);
        setOpen(!open);
      }
    }

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell sx={{p:1}}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => open_subtabla(row?.part_id)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell
            align="center"
            onClick={() => getResources(row["part_id"])}
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:1
            }}
          >
            {row.codigo}
          </TableCell>
          <TableCell
            align="center"
            onClick={() => getResources(row["part_id"])}
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:1
            }}
          >
            {row.nombre}
          </TableCell>
          <TableCell
            align="center"
            onClick={() => getResources(row["part_id"])}
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:1
            }}
          >
            {row.existencia_total}
          </TableCell>
          <TableCell
            align="center"
            onClick={() => getResources(row["part_id"])}
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:1
            }}
          >
            {row.cliente}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, marginBottom:10 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Desgloce de contenedores
                </Typography>
                <Table size="small" aria-label="purchases">
                      <TableHead>
                      <TableRow>
                          <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Serial</TableCell>
                          <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Etiqueta</TableCell>
                          <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Etiqueta master</TableCell>
                          <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Cantidad</TableCell>
                          <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Fecha reporteo</TableCell>
                          <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Fecha impresión</TableCell>
                          <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Usuario impresión</TableCell>
                      </TableRow>
                      </TableHead>
                      <TableBody>
                      {movimientos?.map((mov) => (
                          <TableRow key={mov?.moin_id}>
                            <TableCell align="center" sx={{pl: 0, pr:0, pt:1, pb:1}}>{mov?.moin_serial.length === 1 ? mov?.moin_serial[0]: <VerSerial  key={`serial_${mov?.moin_id}`} data={mov?.moin_serial}/>}</TableCell>
                            <TableCell align="center" sx={{pl: 1.8, pr:1.8, pt:1, pb:1}}>{mov?.etiq_codigo}</TableCell>
                            <TableCell align="center" sx={{pl: 1.8, pr:1.8, pt:1, pb:1}}>{mov?.etiq_master}</TableCell>
                            <TableCell align="center" sx={{pl: 0, pr:0, pt:1, pb:1}}>{mov?.moin_cantidad}</TableCell>
                            <TableCell align="center" sx={{pl: 0, pr:0, pt:1, pb:1}}>{mov?.moin_fechareportado ? mov?.moin_fechareportado : null}</TableCell>
                            <TableCell align="center" sx={{pl: 0, pr:0, pt:1, pb:1}}>{mov?.etiq_fechaimpresion ? mov?.etiq_fechaimpresion : null}</TableCell>
                            <TableCell align="center" sx={{pl: 0, pr:0, pt:1, pb:1}}>{mov?.user}</TableCell>
                          </TableRow>
                      ))}
                      </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }


  return (
    <Paper style={{ height: 350, width: "100%", marginBottom:10 }} sx={{ boxShadow: "none" }}>
      <div style={{display:'flex', justifyContent: 'space-between', marginBottom:-3}}>
        <Typography variant="h5" sx={{fontWeight:500}} gutterBottom component="div">
          Existencias
        </Typography>
        {
          canSearch && (
            <TextField
            id="search_entry"
            label="Ingrese palabra clave"
            name="search_entry"
            size="small"
            value={filtro}
            onChange={handleInputChange}
            onKeyDown={onPressEnter}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <Search sx={{fontSize:20}}/>
                </InputAdornment>
                ),
            }}
            sx={{ color: theme.palette.primary.input, mb: 2 }}
            />
          )
        }
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead
            sx={{
              display: "table-head",
              backgroundColor: theme.palette.primary.main,
              fontWeight: 600,
            }}
          >
            <TableRow>
              <TableCell />
              <TableCell align="center" sx={{ color: "white" }}>
                Código
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Nombre
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Existencia Total
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Cliente
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <Row key={row.serial} row={row}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TablaGeneral;
