import { ThemeContext } from "@emotion/react"
import { ExpandMore } from "@mui/icons-material"
import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, TextField } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { GetAllClientesList } from "../../../../hooks/getListClientes"
import { useLoading } from "../../../../components/Loading/useLoading"
import { GetAllPartesList } from "../../../../hooks/getPartesList"
import { GetAllAlmacenesList } from "../../../../hooks/getListAlmacen"


export const FiltrosExitencias = ({search}) => {

  const theme = useContext(ThemeContext);
  const formOrden = useRef(null);
  const {setAppLoading} = useLoading();
  const { getAllPartesListMutation } = GetAllPartesList();
  const {getAllClientesListMutation} = GetAllClientesList();
  const { getAllAlmacenesListMutation } = GetAllAlmacenesList();
  const [inicio, setInicio] = useState(null);
  const [final, setFinal] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [partes, setPartes] = useState([]);
  const [almacenes, setAlmacenes] = useState([]);
  const [statusSelect, setStatusSelect] = useState([1])
  const [partesFilter, setPartesFilter] = useState([])
  const [clientesSelect, setClientesSelect] = useState([]);
  const [partesSelect, setPartesSelect] = useState([]);
  const [almaSelect, setAlmaSelect] = useState([]);
  const [isOpen, setIsOpen] = useState(true);

  const toggleCollapser = () => {
    setIsOpen(!isOpen);
  };
  const get_clientes = () => {
    getAllClientesListMutation({},{
        onSuccess: (data) => {
            data.sort((a, b) => {
              return a.label.localeCompare(b.label);
            })
            setClientes(data)
        },
        onError: (error) => {
            console.log(error)
        }
    })
  }
  const changeClient = (newValue) => {
    if(newValue.length > 0){
      let partes_select = [...partesSelect];
      let emp_select = new Set(newValue.map(obj => obj.value));
      let partes_filter = partes.filter(obj => emp_select.has(obj.emp_id));
      partes_select = partes_select.filter(obj => emp_select.has(obj.emp_id));
      setPartesSelect(partes_select)
      setPartesFilter(partes_filter)
    }else{
      setPartesFilter(partes)
    }
    setClientesSelect(newValue)
  }
  const get_partes = () => {
    setAppLoading(true);
    getAllPartesListMutation(
      {},
      {
        onSuccess: (data) => {
          setPartes(data);
          setPartesFilter(data)
          setAppLoading(false);
        },
        onError: (error) => {
          console.log(error);
          setAppLoading(false);
        },
      }
    );
  };
  const get_almacenes = () => {
    setAppLoading(true);
    getAllAlmacenesListMutation(
      {},
      {
        onSuccess: (data) => {
          setAlmacenes(data);
          setAppLoading(false);
        },
        onError: (error) => {
          console.log(error);
          setAppLoading(false);
        },
      }
    );
  };
  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault();
    }
  };
  const changeStatus = (value) => {
    let status = [...statusSelect];
    let exist  = statusSelect.some(id => id === value);
    if(exist){
      status = status.filter(item => item !== value);
    }else{
      status.push(value)
    }
    setStatusSelect(status)
  }
  const sumbmitFiltro = () => {
    let partes = partesSelect.map(objeto => objeto.value);
    let clientes = clientesSelect.map(objeto => objeto.value);
    let almacenes = almaSelect.map(objeto => objeto.id);
    let where_filtro = {
      almacenes, 
      status: statusSelect,
      clientes,
      partes,
      fecha_inicio: inicio,
      fecha_fin: final
    }
    search(where_filtro);
  }
  useEffect(() => {
    get_clientes();
    get_partes();
    get_almacenes();
  }, []);


  return(
    <>
    <div className="collapser">
      <div className="collapser-label" onClick={toggleCollapser}>
        <span>Filtros de búsqueda</span>
        <span className="icon">
          <ExpandMore className={isOpen ? 'open' : ''}/>
        </span>
      </div>
      
      <div className={`collapser-content ${isOpen ? 'open' : ''}`}>
        <form ref={formOrden} id="form_ord"  style={{width: '100%'}}>
          <Grid container spacing={0} sx={{width: '100%'}}>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3} sx={{p:'0px 5px'}} className="center_item">
              <FormControl variant="outlined"  fullWidth>
                <FormLabel  id="start_date_label">
                  Fecha inicial
                </FormLabel>
                <TextField
                  id='filtro_start'
                  type="date"
                  fullWidth
                  size="small"
                  value={inicio}
                  name="filtro_start"
                  hiddenLabel
                  placeholder=""
                  sx={{color: theme.palette.primary.input}}
                  onChange={(e)=>setInicio(e.target.value)}
                  className="input-date"
                  />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3} sx={{p:'0px 5px'}} className="center_item">
              <FormControl variant="outlined"  sx={{width: '100%'}}>
                <FormLabel id="end_date_label">
                  Fecha final
                </FormLabel>
                <TextField
                  type="date"
                  size="small"
                  id="end_date"
                  fullWidth
                  value={final}
                  onChange={
                    (e)=>setFinal(e.target.value)
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{p:'0px 5px'}} className="center_item">
              <FormControl variant="outlined"  sx={{width: '100%'}}>
                <FormLabel id="companies">
                  Cliente(s)
                </FormLabel>
                <Autocomplete
                  fullWidth
                  id="emp_id"
                  getOptionLabel={(opcion) => opcion.label}
                  filterSelectedOptions
                  options={clientes}
                  onKeyDown={handleKeyUp}
                  value={clientesSelect}
                  multiple
                  onChange={(e, newValue)=>{
                    changeClient(newValue)
                  }}
                  renderOption={(props, option)=> (
                      <Box {...props} key={option?.value}>{option.label}</Box>
                  )}
                  renderInput={(params) => 
                      <TextField {...params} size="small" fullWidth/>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{p:'0px 5px', mt:2}} className="center_item">
              <FormControl variant="outlined"  sx={{width: '100%'}}>
                <FormLabel id="partes">
                  Parte(s)
                </FormLabel>
                  <Autocomplete
                    fullWidth
                    id="part_id"
                    multiple
                    getOptionLabel={(opcion) => 
                      opcion?.codigo !== undefined ?
                      `${opcion?.label} / ${opcion?.codigo} / ${opcion?.codigo_salida}` :
                      opcion?.label
                    }
                    filterSelectedOptions
                    options={partesFilter}
                    onKeyDown={handleKeyUp}
                    value={partesSelect}
                    onChange={(e, newValue)=>{
                      setPartesSelect(newValue)
                    }}
                    renderOption={(props, option)=> (
                      <Box {...props} key={option?.value}>
                        {option?.codigo !== undefined ? option?.codigo : <></>} / 
                        {option?.codigo_salida !== undefined ? option?.codigo_salida : <></>} / {option?.label}
                      </Box>
                    )}
                    renderInput={(params) => 
                        <TextField {...params} size="small" fullWidth  required/>
                    }
                  />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{p:'0px 5px', mt:2}} className="center_item">
              <FormControl variant="outlined"  sx={{width: '100%'}}>
                <FormLabel id="almacenes">
                  Almacen(es)
                </FormLabel>
                <Autocomplete
                  fullWidth
                  id="alma_id"
                  name="alma_id"
                  multiple
                  getOptionLabel={(opcion) => opcion?.label}
                  filterSelectedOptions
                  options={almacenes}
                  onKeyDown={handleKeyUp}
                  value={almaSelect}
                  onChange={(e, newValue)=>{
                    setAlmaSelect(newValue)
                  }}
                  renderOption={(props, option)=> (
                    <Box {...props} key={option?.id}>{option.label}</Box>
                  )}
                  renderInput={(params) => 
                      <TextField {...params} size="small" fullWidth  required/>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{p:'0px 5px', mt:2}} className="center_item">
              <FormGroup sx={{width:'100%', display:'flex', justifyContent: 'space-between'}} row>
                <FormControlLabel 
                  control={
                    <Checkbox
                    checked={statusSelect.some(id => id === 1)} 
                    onChange={()=>changeStatus(1)}
                    />
                  } 
                    label="Generales" 
                />
                <FormControlLabel 
                  control={
                    <Checkbox checked={statusSelect.some(id => id === 6)} onChange={()=>changeStatus(6)}/>
                  } 
                  label="Cargados" />
                <FormControlLabel 
                  control={
                    <Checkbox checked={statusSelect.some(id => id === 7)} onChange={()=>changeStatus(7)}/>
                  } 
                  label="Procesados" 
                />
                <FormControlLabel 
                  control={
                    <Checkbox checked={statusSelect.some(id => id === 2)} onChange={()=>changeStatus(2)}/>
                  } 
                  label="En Salida" 
                />
              </FormGroup>
            </Grid>
            <Grid item xs={0} sm={0} md={0} lg={3} xl={3} sx={{mt:2}} className="center_item">
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:'0px 5px', mt:2}} className="center_item">
              <Button 
                variant="contained" 
                className="btn_system" 
                onClick={sumbmitFiltro}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
    </>
  )
}