import { Backdrop, Box, Button, Fade, Grid, Modal } from "@mui/material"
import { useEffect, useState } from "react";
import { GetParte } from "../../../../../hooks/getParte";
import { useLoading } from "../../../../../components/Loading/useLoading";
import swal from "sweetalert2";
import { RecursosEnum } from "../../../../../@enums/tipo_images.enum";
import { useSelector } from "react-redux";
import { PhotoLibrary } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 700,
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  display: "flex",
  justifyContent: "center",
};


export const ModalConfirmReport = ({open, setOpen, idMov, setIdMov, goReport}) => {

  const { getParteMutation } = GetParte();
  const { setAppLoading } = useLoading();
  const [info, setInfo] = useState(null);
  const [imgs, setImgs] = useState([]);

  const { apiResources } = useSelector((state) => ({
    apiResources: state.app.apiResources,
}));

  useEffect(() => {
    if (open) {
      setAppLoading(true)
      getParteMutation(idMov, {
        onSuccess: (data) => {
          setAppLoading(false);
          let res = data?.recursos?.filter(img=> img.recu_tipo === RecursosEnum.PRINCIPAL);
          setImgs([...res]);
          setInfo(data);
        },
        onError: (error) => {
          swal
            .fire({
              position: "top-end",
              icon: "error",
              title: "Producto no encontrado",
              showConfirmButton: false,
              timer: 1500,
            })
        },
      });
    }
  }, [open]);

  const handleClose = () => {
    setIdMov(null);
    setOpen(false);
  };

  const confirm = () => {
    goReport(idMov);
    handleClose();
  }


  return (
    <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition
    slots={{ backdrop: Backdrop }}
    slotProps={{
      backdrop: {
        timeout: 500,
      },
    }}
    >
      <Fade in={open}>
        <Box sx={style} className="modal_confirmacion_reporte">
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{p:1, mb:3}} className="center_item">
              <span
              style={{
                fontSize:24,
                fontWeight:600,
                color:'#575E3B'
              }}
              >
                ¿Esta es la parte que deseea reportar?
              </span>
            </Grid>
            {
              imgs.length > 0 && (
              <Grid item xs={6} sx={{p:1}} className="center_item">
                <img 
                src={`${apiResources}${imgs[0]?.recu_ruta}`} 
                alt={imgs[0]?.recu_nombre} 
                style={{
                    height:180,
                    width:'100%'
                }}/>
              </Grid>
              )
            }
            {
              imgs.length === 0 && (
              <Grid item xs={6} sx={{p:1}} className="center_item">
                <PhotoLibrary 
                sx={{
                  color: '#575E3B',
                  fontSize: 80
                }}
                />
              </Grid>
              )
            }
            <Grid item xs={6} sx={{p:1, flexDirection: 'column'}} className="center_item">
              <div
              style={{
                width: '100%',
                marginBottom:10
              }}
              >
                <span
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  color:'#575E3B'
                }}
                >
                  {info?.part_nombre}
                </span>
              </div>
              <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row'
              }}
              >
                <span
                style={{
                  fontSize: 18,
                  fontWeight: 400,
                }}
                >
                  Código de Entrada:
                </span>
                <span
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                  color:'#575E3B',
                  marginLeft:5
                }}
                >
                  {info?.part_codigoentrada}
                </span>
              </div>
              <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row'
              }}
              >
                <span
                style={{
                  fontSize: 18,
                  fontWeight: 400,
                }}
                >
                  Código de Salida:
                </span>
                <span
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                  color:'#575E3B',
                  marginLeft:5
                }}
                >
                  {info?.part_codigosalida}
                </span>
              </div>
            </Grid>
            <Grid item xs={6} sx={{p:1, mt:3}} className="center_item">
              <Button
              variant="outlined"
              className="btn_system"
              sx={{ p:'0px 5px' }}
              disableElevation
              onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6} sx={{p:1, mt:3}} className="center_item">
              <Button
              variant="contained"
              className="btn_system"
              sx={{ p:'0px 5px' }}
              disableElevation
              onClick={confirm}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  )
}