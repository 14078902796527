import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { GetAllPartesList } from "../../../../hooks/getPartesList";
import { GetOrdenCliente } from "../../../../hooks/getListOrdenCliente";
import { GetAllAlmacenesList } from "../../../../hooks/getListAlmacen";
import Spliteo from "./spliteo";
import ModalEncuesta from "../../productos/components/modales/modalEncuesta/modalEncuesta";
import validateNumber from "../../../../helpers/validateInt";
import { AccionMovInvBackEnum } from "../../../../@enums/action_mov_inv_back";
import { TiposCamposExtraEnum } from "../../../../@enums/tipo_campo_extra";
import { ThemeContext } from "@emotion/react";
import CarouselImg from "../../../../components/carousel";
import { RecursosEnum } from "../../../../@enums/tipo_images.enum";
import { useLoading } from "../../../../components/Loading/useLoading";

const DataMovimiento = ({
  setOpenNew,
  form,
  setMovimientos,
  setMovSend,
  movimientos,
  movSend,
  item,
  setItem,
  isUpdate,
  idClient,
  setIdClie,
  extras,
  getData,
  openData,
  guarda,
  guardarData,
  setSaving,
}) => {
  const theme = useContext(ThemeContext);
  const {setAppLoading} = useLoading();
  const { getAllPartesListMutation } = GetAllPartesList();
  const { getAllAlmacenesListMutation } = GetAllAlmacenesList();
  const { getOrdenClienteMutation } = GetOrdenCliente();
  const [partes, setPartes] = useState([]);
  const [almacenes, setAlmacenes] = useState([]);
  const [ordenes, setOrdenes] = useState([]);
  const [nameCues, setNameCues] = useState(item !== null ? item?.nameCues : "");
  const [parteSelect, setParteSelect] = useState(
    item !== null ? { label: item?.parte, value: item?.part_id } : null
  );
  const [almSelect, setAlmSelect] = useState(
    item !== null ? { label: item?.almacen, id: item?.alma_id } : null
  );
  const [errCant, setErrCant] = useState(false);
  const [errPart, setErrPart] = useState(false);
  const [errOrd, setErrOrd] = useState(false);
  const [errAlm, setErrAlm] = useState(false);
  const [errCues, setErrCues] = useState(false);
  const [almDefault, setAlmDefault] = useState(item?.alma_id);
  const [orden, setOrden] = useState(item?.orden);
  const [idCert, setIdCert] = useState(item !== null ? item?.cues_id : null);
  const [openEnc, setOpenEnc] = useState(false);
  const [respuestas, setRespuestas] = useState(
    item?.respuestas !== null ? item?.respuestas : []
  );
  const [inputExtra, setInputExtra] = useState(
    item !== null ? item?.extras_data : extras
  );
  const [paquetes, setPaquetes] = useState(item !== null ? item?.spliteo : []);
  const [totalPiezas, setTotalPiezas] = useState(
    item !== null ? item?.domo_cantidad : 0
  );
  const [tipoSplit, setTipoSplit] = useState(
    item !== null ? item?.tipo_split : 1
  );
  const [imagenes, setImagenes] = useState(item !== null ? item?.imgs : []);

  const get_partes = () => {
    setAppLoading(true);
    getAllPartesListMutation(
      {},
      {
        onSuccess: (data) => {
          let parts = data.filter((part) => part.emp_id === idClient);
          setPartes(parts);
          setAppLoading(false);
        },
        onError: (error) => {
          console.log(error);
          setAppLoading(false);
        },
      }
    );
  };
  const get_almacenes = () => {
    setAppLoading(true);
    getAllAlmacenesListMutation(
      {},
      {
        onSuccess: (data) => {
          setAlmacenes(data);
          setAppLoading(false);
        },
        onError: (error) => {
          console.log(error);
          setAppLoading(false);
        },
      }
    );
  };
  const get_ordenes = () => {
    setAppLoading(true);
    getOrdenClienteMutation(idClient, {
      onSuccess: (data) => {
        console.log(data)
        setOrdenes(data);
        setAppLoading(false);
      },
      onError: (error) => {
        console.log(error);
        setAppLoading(false);
      },
    });
  };

  useEffect(() => {
    get_partes();
    get_almacenes();
    get_ordenes();
  }, []);

  useEffect(() => {
    get_partes();
    get_ordenes();
    if (item !== null && !item?.do_encuesta) {
      setRespuestas([]);
    }
  }, [setIdClie]);

  useEffect(() => {
    setInputExtra(item !== null ? item?.extras_data : extras);
  }, [setItem]);

  useEffect(() => {
    if (guarda) {
      save();
    }
  }, [guarda]);

  const validateForm = () => {
    setErrPart(false);
    setErrAlm(false);
    setErrOrd(false);
    if (
      parteSelect === null ||
      parteSelect === undefined ||
      almSelect === null ||
      almSelect === undefined ||
      paquetes.length === 0 ||

      orden === undefined ||
      (idCert !== null && respuestas.length === 0)
    ) {
      if (paquetes.length === 0) setErrCant(true);
      if (parteSelect === null || parteSelect === undefined) setErrPart(true);
      if (almSelect === null || almSelect === undefined) setErrAlm(true);
      if (orden === undefined) setErrOrd(true);
      if (idCert !== null && respuestas.length === 0) setErrCues(true);

      return false;
    } else {
      return true;
    }
  };

  const resetFormMov = () => {
    setAlmSelect(null);
    setParteSelect(null);
    form.current.domo_referencia.value = "";
    form.current.part_id.value = "";
    form.current.alma_id.value = "";
    setItem(null);
    setOrden(null);
    setRespuestas([]);
    setIdCert(null);
    setInputExtra([]);
    setImagenes([]);
  };

  const save = () => {
    if (validateForm()) {

      let data_res = respuestas;

      data_res.forEach((item) => {
        item.cure_respuesta = Array.isArray(item?.cure_respuesta)
          ? JSON.stringify(item?.cure_respuesta)
          : item?.cure_respuesta;
      });

      if (item !== null) {
        let index = movimientos.findIndex((objeto) => objeto.id === item?.id);
        let mov = [...movimientos];
        let mov_send = [...movSend];
        mov[index] = {
          id: item?.id,
          parte: parteSelect?.label,
          domo_cantidad: parseInt(totalPiezas),
          serial: item?.serial,
          domo_referencia: form.current.domo_referencia.value,
          almacen: almSelect?.label,
          part_id: parteSelect?.value,
          alma_id: almSelect?.id,
          orden: orden,
          respuestas: data_res,
          cues_id: idCert,
          do_encuesta: item?.do_encuesta,
          extras_data: inputExtra,
          spliteo: paquetes,
          tipo_split: tipoSplit,
          imgs: imagenes,
          nameCues: nameCues,
        };
        mov_send[index] = {
          domo_id: item?.id,
          domo_cantidad: parseInt(totalPiezas),
          domo_referencia: form.current.domo_referencia.value,
          part_id: parteSelect?.value,
          alma_id: almSelect?.id,
          moin_id: mov_send[index]?.moin_id,
          action: mov_send[index]?.action,
          domo_extradata: JSON.stringify({
            ordenCompra: orden,
            campos_extra: inputExtra,
            tipo_split: tipoSplit,
          }),
          respuestas: data_res,
          cues_id: idCert,
          do_encuesta: item?.do_encuesta,
          spliteo: paquetes,
          serial: mov_send[index]?.serial,
          orden: orden
        };
        setMovimientos([...mov]);
        setMovSend([...mov_send]);
        guardarData([mov_send[index]]);
      } else {
        let dataMov = {
          id:
            movSend[movSend?.length - 1]?.domo_id + 1 ||
            movimientos?.length + 1,
          parte: parteSelect?.label,
          domo_cantidad: parseInt(totalPiezas),
          serial: null,
          domo_referencia: form.current.domo_referencia.value,
          almacen: almSelect?.label,
          part_id: parteSelect?.value,
          alma_id: almSelect?.id,
          orden: orden,
          respuestas: data_res,
          cues_id: idCert,
          do_encuesta: true,
          extras_data: inputExtra,
          spliteo: paquetes,
          tipo_split: tipoSplit,
          imgs: imagenes,
          nameCues: nameCues,
        };
        let dataSend;
        if (isUpdate) {
          dataSend = {
            domo_id:
              movSend[movSend?.length - 1]?.domo_id + 1 ||
              movimientos?.length + 1,
            domo_cantidad: parseInt(totalPiezas),
            domo_referencia: form.current.domo_referencia.value,
            part_id: parteSelect?.value,
            alma_id: almSelect?.id,
            moin_id: null,
            action: AccionMovInvBackEnum.CREAR,
            domo_extradata: JSON.stringify({
              ordenCompra: orden,
              campos_extra: inputExtra,
              tipo_split: tipoSplit,
            }),
            respuestas: data_res,
            cues_id: idCert,
            do_encuesta: true,
            spliteo: paquetes,
            serial: null,
            orden: orden
          };
        } else {
          dataSend = {
            domo_cantidad: parseInt(totalPiezas),
            domo_referencia: form.current.domo_referencia.value,
            part_id: parteSelect?.value,
            alma_id: almSelect?.id,
            domo_extradata: JSON.stringify({
              ordenCompra: orden,
              campos_extra: inputExtra,
              tipo_split: tipoSplit,
            }),
            respuestas: data_res,
            cues_id: idCert,
            spliteo: paquetes,
            serial: null,
            orden: orden
          };
        }
        let mov = movimientos;

        setMovimientos([...mov, dataMov]);
        setMovSend([...movSend, dataSend]);
        guardarData([dataSend]);
      }
    } else {
      setSaving(false);
    }
  };

  const cancelMov = () => {
    setOpenNew(false);
    resetFormMov();
  };

  const changePart = (id) => {
    let part = partes.find((item) => item.value === parseInt(id.value));
    setParteSelect(part);
    setImagenes(part?.recursos);
    setRespuestas([]);
    if (part?.alma_default) {
      let alm = almacenes.find(
        (item) => item.id === parseInt(part?.alma_default)
      );
      setAlmSelect(alm);
      setAlmDefault(part?.alma_default);
      setErrAlm(false);

      let ruta = part?.rutas.filter(
        (item) => item.alma_id === part?.alma_default
      );
      setIdCert(ruta[0]?.cues_id);
      setNameCues(ruta[0]?.cuestionarios?.cues_nombre?.toUpperCase());
    } else {
      setAlmDefault(null);
      setAlmSelect(null);
      setIdCert(null);
    }
    setErrPart(false);
  };

  const changeAlmacen = (id) => {
    let alm = almacenes.find((item) => item.id === parseInt(id.id));
    setAlmSelect(alm);
    setAlmDefault(alm?.id);
    let ruta = parteSelect?.rutas.filter((item) => item.alma_id === id.id);
    setIdCert(ruta.length > 0 ? ruta[0]?.cues_id : null);
    setNameCues(
      ruta.length > 0 ? ruta[0]?.cuestionarios?.cues_nombre?.toUpperCase() : ""
    );
    setRespuestas([]);
  };

  const openEncuesta = () => {
    setOpenEnc(true);
  };

  const answerExtras = (index, value) => {
    let camp_ext = inputExtra;
    camp_ext[index].respuesta = value;
    setInputExtra([...camp_ext]);
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault();
    }
};

  useEffect(() => {
    if(item?.part_id && partes?.length > 0){
      let part = partes.find((parte) => item?.part_id === parte?.value);
      setParteSelect({ label: `${part?.codigo ? part?.codigo : "S/C"} / ${part?.codigo_salida ? part?.codigo_salida : "S/C"} / ${part?.label}`, value: part?.value });
    }
  }, [item, partes]);

  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={12}
        sx={{
          p: 1,
          textAlign: "start",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography id="transition-modal-title" variant="h6" component="h2">
          Datos Movimiento
        </Typography>
      </Grid>
      
      <Grid
            item
            xs={12}
            sx={{ p: 1, textAlign: "start", display: "flex", alignItems: "center" }}
          >
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={2}
                xl={2}
                sx={{ p: 1, display: "flex", justifyContent: "center" }}
              >
                <CarouselImg
                  key={"carousel"}
                  recursos={imagenes?.filter(
                    (res) => +res?.recu_tipo === RecursosEnum.PRINCIPAL
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={10}
                xl={10}
                sx={{ p: 1, display: "flex", justifyContent: "center" }}
              >
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                    sx={{ p: 1, display: "flex", justifyContent: "center" }}
                  >
                    <FormControl fullWidth size="small" error={errPart}>
                      <Autocomplete
                        fullWidth
                        id="part_id"
                        getOptionLabel={(opcion) => 
                          opcion?.codigo !== undefined ?
                          `${opcion?.label} / ${opcion?.codigo} / ${opcion?.codigo_salida} / ${opcion?.codigo_alterno}` :
                          opcion?.label
                        }
                        filterSelectedOptions
                        options={partes}
                        disabled={isUpdate}
                        onKeyDown={handleKeyUp}
                        value={parteSelect}
                        onChange={(e, newValue)=>{
                            if(newValue === null){
                              changePart('')
                            }else{
                              changePart(newValue)
                            }
                        }}
                        renderOption={(props, option)=> (
                            <Box {...props} key={option?.value}>
                              {option?.codigo !== undefined ? option?.codigo : <></>} / 
                              {option?.codigo_salida !== undefined ? option?.codigo_salida : <></>} / 
                              {option?.codigo_alterno !== undefined ? option?.codigo_alterno : <></>} / {option?.label}
                            </Box>
                        )}
                        renderInput={(params) => 
                            <TextField {...params} label="Parte" size="small" fullWidth  required/>
                        }
                        />
                        <FormHelperText>
                          {errPart ? "Seleccione una parte" : ""}
                        </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                    sx={{ p: 1, display: "flex", justifyContent: "center" }}
                  >
                    <FormControl fullWidth size="small" error={errAlm}>
                      <Autocomplete
                        fullWidth
                        id="alma_id"
                        name="alma_id"
                        getOptionLabel={(opcion) => opcion?.label}
                        filterSelectedOptions
                        options={almacenes}
                        disabled={isUpdate}
                        onKeyDown={handleKeyUp}
                        value={almSelect}
                        onChange={(e, newValue)=>{
                            if(newValue === null){
                              changeAlmacen('')
                            }else{
                              changeAlmacen(newValue)
                            }
                        }}
                        renderOption={(props, option)=> (
                          <Box {...props} key={option?.id}>{option.label}</Box>
                        )}
                        renderInput={(params) => 
                            <TextField {...params} label="Almacen" size="small" fullWidth  required/>
                        }
                        />
                        <FormHelperText>
                          {errAlm ? "Seleccione un almacen" : ""}
                        </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                    sx={{ p: 1, display: "flex", justifyContent: "center" }}
                  >
                    <TextField
                      id="domo_referencia"
                      size="small"
                      label="Referencia"
                      defaultValue={item?.domo_referencia}
                      disabled={isUpdate && item !== null}
                      inputProps={{ maxLength: 50 }}
                      fullWidth
                      sx={{ color: theme.palette.primary.input }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                    sx={{ p: 1, display: "flex", justifyContent: "center" }}
                  >
                      <FormControl fullWidth size="small" error={errOrd}>
                        <Autocomplete
                          fullWidth
                          id="orden"
                          name="orden"
                          getOptionLabel={(opcion) => opcion?.label}
                          filterSelectedOptions
                          options={ordenes}
                          disabled={isUpdate && item !== null}
                          onKeyDown={handleKeyUp}
                          value={ordenes?.find(o => o?.value === orden)}
                          onChange={(e, newValue)=>{
                              if(newValue === null){
                                setOrden(undefined)
                              }else{
                                setOrden(newValue?.value)
                              }
                          }}
                          renderOption={(props, option)=> (
                            <Box {...props} key={option?.value}>{option.label}</Box>
                          )}
                          renderInput={(params) => 
                              <TextField {...params} label="Orden de compra" size="small" fullWidth  required/>
                          }
                          />
                          <FormHelperText>
                            {errOrd ? "Seleccione una orden" : ""}
                          </FormHelperText>
                      </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                    sx={{ p: 1, display: "flex", justifyContent: "center" }}
                  ></Grid>
                  {idCert !== null && totalPiezas > 0 && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={4}
                      sx={{
                        p: 1,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Button
                        variant="contained"
                        className="btn_system"
                        onClick={openEncuesta}
                        sx={{ border: errCues ? "1px solid red" : "none" }}
                      >
                        {nameCues}
                      </Button>
                      {errCues && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          Complete el cuestionario
                        </span>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
      </Grid>

      <Spliteo
        key={"spliteo_mov"}
        formData={form}
        paquetes={item !== null ? item?.spliteo : paquetes}
        setPaquetes={setPaquetes}
        piezas={totalPiezas}
        setPiezas={setTotalPiezas}
        errCantidad={errCant}
        setErrCantidad={setErrCant}
        tipoSplit={tipoSplit}
        setTipoSplit={setTipoSplit}
        isUpdate={isUpdate && item !== null}
        id={item?.id}
        getData={getData}
        openData={openData}
        closeItem={cancelMov}
        movId={item?.mov_inv}
      />

      {extras?.length > 0 && (
        <Grid
          item
          xs={12}
          sx={{
            p: 1,
            textAlign: "start",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Datos Adicionales del Movimiento
          </Typography>
        </Grid>
      )}
      {inputExtra?.map((pregunta, index) => (
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={3}
          xl={3}
          sx={{ p: 1, display: "flex", justifyContent: "center" }}
        >
          {pregunta?.tipo === TiposCamposExtraEnum.BOLEANO && (
            <FormControl fullWidth size="small">
              <InputLabel id={`bool_${pregunta?.nombre_campo}`}>
                {pregunta?.nombre_campo}
              </InputLabel>
              <Select
                labelId={`bool_${pregunta?.nombre_campo}`}
                id="recurso"
                name="recurso"
                label={pregunta?.nombre_campo}
                defaultValue={pregunta?.respuesta}
                disabled={isUpdate && item !== null}
                onChange={(e) => answerExtras(index, e.target.value)}
              >
                <MenuItem value="Si">Sí</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          )}
          {pregunta?.tipo === TiposCamposExtraEnum.FLOTANTE && (
            <TextField
              id={`float_${pregunta?.nombre_campo}`}
              label={pregunta?.nombre_campo}
              size="small"
              fullWidth
              defaultValue={
                pregunta?.respuesta !== null
                  ? parseInt(pregunta?.respuesta)
                  : null
              }
              disabled={isUpdate && item !== null}
              onKeyDown={(e) => validateNumber(e)}
              onKeyUp={(e) => answerExtras(index, parseInt(e.target.value))}
              sx={{ color: theme.palette.primary.input }}
            />
          )}
          {pregunta?.tipo === TiposCamposExtraEnum.TEXTO && (
            <TextField
              id={`float_${pregunta?.nombre_campo}`}
              label={pregunta?.nombre_campo}
              defaultValue={pregunta?.respuesta}
              onChange={(e) => answerExtras(index, e.target.value)}
              sx={{ color: theme.palette.primary.input }}
              disabled={isUpdate && item !== null}
              size="small"
              fullWidth
            />
          )}
        </Grid>
      ))}

      {idCert !== null && totalPiezas > 0 && (
        <ModalEncuesta
          key={"modal_enc"}
          open={openEnc}
          setOpen={setOpenEnc}
          id_cuest={idCert}
          answers={isUpdate ? item?.respuestas : respuestas}
          setAnswers={setRespuestas}
          onlyRead={item !== null && !item?.do_encuesta ? true : false}
          cantidad={totalPiezas}
          id_item={null}
        />
      )}
    </Grid>
  );
};

export default DataMovimiento;
