import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Table,
  TableContainer,
  TextField,
} from "@mui/material";
import {
  AddCircle,
  ContentCopy,
  DeleteOutline,
  Edit,
  Visibility,
} from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { GetCertificados } from "../../../../hooks/getCertificados";
import { DeleteCertificado } from "../../../../hooks/deleteCertificado";
import { CopyCertificado } from "../../../../hooks/copyCertificado";
import ModalEncuesta from "./modales/modalEncuesta/modalEncuesta";
import { TableVirtuoso } from "react-virtuoso";
import { ThemeContext } from "@emotion/react";
import swal from "sweetalert2";
import { GetCertificadosList } from "../../../../hooks/getListCertificados";
import { ModalEditarEncuesta } from "./modales/modalEncuesta/modalEditarEncuesta";
import { useLoading } from "../../../../components/Loading/useLoading";

const CertificadosProductos = ({ setOpenCrear, part_id, open }) => {
  const theme = useContext(ThemeContext);
  const {setAppLoading} = useLoading();

  const { getCertificadosMutation } = GetCertificados();
  const { getCertificadosListMutation } = GetCertificadosList();
  const { deleteCertificadoMutation } = DeleteCertificado();
  const { copyCertificadoMutation } = CopyCertificado();
  const [rows, setRows] = useState([]);
  const [openVistaPrev, setOpenVistaPrev] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [idCert, setIdCert] = useState(null);
  const [openCopy, setOpenCopy] = useState(false);
  const [certificados, setCertificados] = useState(null);
  const [selectCert, setSelectCert] = useState(null);

  //DATOS
  const getData = () => {
    setAppLoading(true);
    getCertificadosMutation(part_id, {
      onSuccess: (data) => {
        setRows(data);
        setAppLoading(false);
      },
      onError: (error) => {
        console.log(error);
      },
    });
    setAppLoading(true);
    getCertificadosListMutation(0, {
      onSuccess: (data) => {
        setCertificados(data);
        setAppLoading(false);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const deleteCertificado = (id) => {
    swal
      .fire({
        title: "¿Esta seguro de elimanar este certificado?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
      })
      .then((result) => {
        if (result.isConfirmed) {
          setAppLoading(true);
          deleteCertificadoMutation(id, {
            onSuccess: (data) => {
              setAppLoading(false);
              if (data.success) {
                swal
                  .fire({
                    title: "Eliminado",
                    text: "El certificado se elimino correctamente",
                    icon: "success",
                  })
                  .then(() => {
                    setTimeout(() => {
                      
                      getData();
                    }, 800);
                  });
              } else {
                let mensaje =
                  data.mensaje !== null
                    ? data.mensaje
                    : "Hubo un problema al eliminar el certificado, intentalo de nuevo";

                swal
                  .fire({
                    position: "center",
                    icon: "error",
                    title: mensaje,
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  .then(() => {
                    setTimeout(() => {
                      getData();
                    }, 1800);
                  });
              }
            },
            onError: (error) => {
              setAppLoading(false);
              swal
                .fire({
                  position: "center",
                  icon: "error",
                  title:
                    "Hubo un problema al eliminar el certificado, intentalo de nuevo",
                  showConfirmButton: false,
                  timer: 1500,
                })
                .then(() => {
                  setTimeout(() => {
                    getData();
                  }, 1800);
                });
            },
          });
        }
      });
  };

  const visualizar = (id) => {
    setIdCert(id);
    setOpenVistaPrev(true);
  };

  const editar = (id) => {
    setIdCert(id);
    setOpenEdit(true);
  }

  const closeCopy = () => {
    setSelectCert(null);
    setOpenCopy(false);
  };

  const copyCert = () => {
    setAppLoading(true);
    copyCertificadoMutation(
      { id: part_id, data: { cues_id: selectCert?.id } },
      {
        onSuccess: (data) => {
          closeCopy();
          if (data.success) {
            swal
              .fire({
                position: "top-end",
                icon: "success",
                title: "Certificado copiado con éxito",
                showConfirmButton: false,
                timer: 1500,
              })
              .then(() => {
                setTimeout(() => {
                  getData();
                }, 800);
              });
          } else {
            swal.fire({
              position: "top-end",
              icon: "error",
              title: "Fallo al copiar certificado",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        },
        onError: (error) => {
          console.log(error);
          closeCopy();
          swal.fire({
            position: "top-end",
            icon: "error",
            title: "Fallo al copiar certificado",
            showConfirmButton: false,
            timer: 1500,
          });
        },
      }
    );
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [open]);

  //TABLA
  const columns = [
    {
      width: 200,
      label: "Nombre",
      dataKey: "cues_nombre",
    },
    {
      width: 50,
      label: "Acciones",
      dataKey: "acciones",
    },
  ];

  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table
        {...props}
        sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
      />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
  };

  function fixedHeaderContent() {
    return (
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            align="center"
            style={{ width: column.width }}
            sx={{
              display: "table-cell",
              backgroundColor: theme.palette.primary.main,
              color: "white",
              fontWeight: 600,
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function rowContent(_index, row) {
    return (
      <React.Fragment>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            align="center"
            sx={{
              fontWeight: 500,
              color: theme.palette.primary.input,
            }}
          >
            {column.dataKey === "acciones" ? (
              <div>
                <Button
                  variant="contained"
                  sx={{
                    ml: 1,
                    p: 1,
                    width: "40px !important",
                    minWidth: "40px",
                  }}
                  onClick={() => deleteCertificado(row?.cues_id)}
                >
                  <DeleteOutline sx={{ fontSize: 20 }} />
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    ml: 1,
                    p: 1,
                    width: "40px !important",
                    minWidth: "40px",
                  }}
                  onClick={() => editar(row?.cues_id)}
                >
                  <Edit sx={{ fontSize: 20 }} />
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    ml: 1,
                    p: 1,
                    width: "40px !important",
                    minWidth: "40px",
                  }}
                  onClick={() => visualizar(row?.cues_id)}
                >
                  <Visibility sx={{ fontSize: 20 }} />
                </Button>
              </div>
            ) : (
              row[column.dataKey]
            )}
          </TableCell>
        ))}
      </React.Fragment>
    );
  }

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={{ p: 0 }}>
          <IconButton size="large" onClick={() => setOpenCrear(true)}>
            <AddCircle sx={{ fontSize: 40 }} />
          </IconButton>
          <IconButton size="medium" onClick={() => setOpenCopy(true)}>
            <ContentCopy sx={{ fontSize: 35 }} />
          </IconButton>
        </Grid>
        {openCopy && (
          <Grid item xs={12} sx={{ p: 0 }}>
            <Grid container spacing={0}>
              <Grid item xs={5} sx={{ p: 1 }}>
                <FormControl fullWidth size="small">
                  <Autocomplete
                  fullWidth
                  id="certificate"
                  getOptionLabel={(opcion) => opcion.label}
                  filterSelectedOptions
                  options={certificados}
                  value={selectCert}
                  onChange={(e, newValue)=>{
                      if(newValue === null){
                        setSelectCert(null)
                      }else{
                        setSelectCert(newValue)
                      }
                  }}
                  renderOption={(props, option)=> (
                      <Box {...props} key={option?.id}>{option.label}</Box>
                  )}
                  renderInput={(params) => 
                      <TextField {...params} label="Seleccione un certificado" size="small" fullWidth  required/>
                  }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2} sx={{ p: 1 }}>
                <Button
                  variant="contained"
                  className="btn_system"
                  onClick={copyCert}
                >
                  Copiar
                </Button>
              </Grid>
              <Grid item xs={2} sx={{ p: 1 }}>
                <Button
                  variant="contained"
                  className="btn_system"
                  onClick={closeCopy}
                >
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} sx={{ p: 0 }}>
          <Paper
            style={{ height: '95vh', width: "100%" }}
            sx={{ boxShadow: "none" }}
          >
            <TableVirtuoso
              data={rows}
              components={VirtuosoTableComponents}
              fixedHeaderContent={fixedHeaderContent}
              itemContent={rowContent}
              style={{
                boxShadow: "none",
                border: "1px solid #f2f3f3",
              }}
            />
          </Paper>
        </Grid>
      </Grid>
      <ModalEncuesta
        key={"modal_enc"}
        open={openVistaPrev}
        setOpen={setOpenVistaPrev}
        id_cuest={idCert}
        onlyRead={true}
        answers={[]}
        cantidad={null}
        id_item={null}
      />
      <ModalEditarEncuesta
      key={'modal_edit_enc'}
      open={openEdit}
      setOpen={setOpenEdit}
      id_cert={idCert}/>
    </>
  );
};

export default CertificadosProductos;
