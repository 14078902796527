import React, { useContext, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useUpdateGiroEmpresa } from "../../../../../hooks/empresas";
import { Button, Grid, TextField } from "@mui/material";
import { ThemeContext } from "@emotion/react";
import validateNumber from "../../../../../helpers/validateInt";
import swal from 'sweetalert2'

const ActualizarGiro = ({ closeModal, empresa, porcentajeDisponible, giro }) => {
    const formGir = useRef(null);
    const theme = useContext(ThemeContext);
    const queryClient = useQueryClient();
    const { updateGiroEmpresa, isUpdatingGiroEmpresa } = useUpdateGiroEmpresa();
    const [errorName, setErrorName] = useState(false);
    const [porcent, setPorcent] = useState(giro?.giro_porcentaje);


    const validateForm = () => {
        if(formGir.current.giro_nombre.value === "" ||
            formGir.current.giro_porcentaje.value === "" ||
            parseInt(formGir.current.giro_porcentaje.value) < 1 ||
            parseInt(formGir.current.giro_porcentaje.value) > porcentajeDisponible ||
            porcentajeDisponible === 0
            
        ){
            if(formGir.current.giro_nombre.value === "")setErrorName(true)
            return false;
        }else{
            return true;
        }
    }

    const guardarGiro = () => {
        if(validateForm()){
            let values = {
                giro_nombre: formGir.current.giro_nombre.value,
                giro_porcentaje: porcent,
                emp_id: empresa?.emp_id,
                giro_id: giro?.giro_id
            };
            updateGiroEmpresa(values, {
                onSuccess: res => {
                    if (res.success) {
                        swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Giro actualizado con éxito",
                            showConfirmButton: false,
                            timer: 1500
                        })
                        queryClient.invalidateQueries(["empresas", "giros"]);
                        closeModal();
                    } else {
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Fallo al actualizar giro",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }, onError: () => {
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Fallo al actualizar giro",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
        }
    }

    return(
        <div style={{width:'100%'}}>
            <Grid container spacing={0}>
                <form ref={formGir}>
                    <TextField
                    id="giro_nombre"
                    size="small"
                    variant="standard"
                    label="Giro"
                    fullWidth
                    required
                    defaultValue={giro?.giro_nombre}
                    onChange={(e)=> {
                        e.target.value.length > 0 ?  
                        setErrorName(false) :
                        setErrorName(true)
                    }}  
                    error={errorName}
                    helperText={errorName ? 'Ingrese un valor' : ''}
                    sx={{color: theme.palette.primary.input, mt:1,ml:1}}
                    />

                    <TextField
                    id="giro_porcentaje"
                    size="small"
                    label={
                        <div style={{ display: "flex", gap: 15 }}>
                            <span>Porcentaje</span>
                            <div style={{ display: "flex", gap: 5 }}>
                                (<span style={{ color: "#aaa" }}>Disponible: </span>
                                <span style={{ color: "#aaa" }}>{porcentajeDisponible}</span>)
                            </div>
                        </div>
                    }
                    variant="standard"
                    fullWidth
                    defaultValue={giro?.giro_porcentaje}
                    onKeyDown={(e)=>{
                        validateNumber(e)
                    }
                    }
                    onKeyUp={(e)=>{
                        setPorcent(parseInt(e.target.value))}
                    }
                    error={porcent < 1 || porcent > porcentajeDisponible || porcent === null || porcentajeDisponible === 0}
                    helperText={
                        porcentajeDisponible === 0 ? 'No hay porcentaje disponible a asignar' :
                        porcent < 1 || porcent > porcentajeDisponible || porcent === null ? 
                        'Ingrese un porcentaje válido' : ''
                    }  
                    sx={{color: theme.palette.primary.input, mt:1,ml:1}}
                    />
                    <div style={{ marginTop:20, display:'flex', justifyContent:'space-between', width:'100%', }}>
                        <Button variant="outlined" onClick={closeModal}>Cancelar</Button>
                        <Button variant="contained" onClick={guardarGiro}>Guardar</Button>
                    </div>
                </form>
            </Grid>
        </div>
    )
}

export default ActualizarGiro;