import { ThemeContext } from "@emotion/react";
import { Container, Grid, Paper } from "@mui/material";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { REACT_APP_ENVIRONMENT } from "../../env";

const Home = () => {

    const theme = useContext(ThemeContext)

    const modules = [
        {
            index: 1,
            name: 'Inventario',
            redirect: '/inventarios/partes'
        },
        {
            index: 2,
            name: 'Administración',
            redirect: '/administracion/usuarios'
        },
        {
            index: 3,
            name: 'Reportes',
            redirect: '/reportes/existencias'
        }
    ]
    return(
        <>
            {
                REACT_APP_ENVIRONMENT !== 'production' && 
                <Grid 
                    key='container_development_tag' 
                    container 
                    spacing={0}
                    sx={{
                    backgroundColor: '#FE0000'
                    }}
                >
                <Grid item xs={12} sx={{p:1}} >
                    <span 
                            key='development_tag'
                            style={{
                            color: 'white',
                            fontWeight: 700,
                            fontSize: 20
                            }}
                    >
                            Entorno de Desarrollo
                    </span>
                </Grid>
                </Grid>
            }
            <Container sx={{paddingTop:10, paddingLeft:'80px !important', paddingRight: '80px !important'}}>
                <Paper elevation={5} square>
                    <Grid container spacing={0} sx={{padding:4}}>
                        <Grid item xs={12} sx={{marginBottom:0}}>
                            <span style={{color: theme.palette.primary.title,}} className="title_lg">
                                Modulos
                            </span>
                        </Grid>
                        <Grid item xs={12} sx={{marginTop:-2}}>
                            <span style={{color: theme.palette.primary.title}} className="title_lg">
                                -------
                            </span>
                        </Grid>
                        <Grid item xs={12} sx={{marginTop:3}}>
                            <span style={{color: theme.palette.primary.title,}} className="title_md">
                                Centros de Trabajo
                            </span>
                        </Grid>
                        {
                            modules.map(module => (
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{marginTop:3}} >
                                    <Link to={module.redirect} style={{display:'flex', width:'min-content'}}>
                                        <span style={{color: theme.palette.primary.title, display:'flex', alignItems:'center'}} className="title_md">
                                            <img src={require(`../resources/imgs/icon_menu_${module.index}.svg`)} alt="img_centro" className="img_centro" />
                                            {module.name}
                                        </span>
                                    </Link>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Paper>
            </Container>
        </>
        
    )
}

export default Home;