import React, { useContext, useState } from "react";
import { Box, Button, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField, Alert } from "@mui/material";
import './styles.css'
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ThemeContext } from "@emotion/react";
import img_lat from '../resources/imgs/loginimg.png'
import { useDispatch } from "react-redux";
import { AUTH_LOGIN } from "../../redux/reducers/auth.reducer";
import { UseLogin } from "../../hooks/login";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../../components/Loading/useLoading";
import { REACT_APP_ENVIRONMENT } from "../../env";


const Login = () => {
    const { setAppLoading } = useLoading();
    const theme = useContext(ThemeContext)
    const dispatch = useDispatch();
    const {mutateLogin} = UseLogin()
    const navigate = useNavigate();
    setAppLoading(false)

    const [showPassword, setShowPassword] = useState(false);
    const [alerta, setAlerta] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleSubmit = (event) => {
        setAppLoading(true);
        event.preventDefault();
        mutateLogin({
            "username": event.target.username.value,
            "password": event.target.password.value
            }, {
                onSuccess: (data) => {
                    setAppLoading(false);
                    if(data){
                        dispatch(
                            AUTH_LOGIN({user: data, token: data.access_token, permissions: data.permissions})
                        )
                        setAlerta(false)
                        navigate(`/home`);
                    }else{
                        setAlerta(true)
                    }
                }, 
                onError: (error) => {
                    console.log(error)
                    setAlerta(true)
                    setAppLoading(true);
                }
            })
        
    }

    return(
        <>
            {
                REACT_APP_ENVIRONMENT !== 'production' && 
                    <Grid 
                        key='container_development_tag' 
                        container 
                        spacing={0}
                        sx={{
                        backgroundColor: '#FE0000'
                        }}
                    >
                        <Grid item xs={12} sx={{p:1}} >
                            <span 
                                key='development_tag'
                                style={{
                                color: 'white',
                                fontWeight: 700,
                                fontSize: 20
                                }}
                            >
                                Entorno de Desarrollo
                            </span>
                        </Grid>
                    </Grid>
            }
            <Container sx={{paddingTop:10, paddingLeft:'80px !important', paddingRight: '80px !important'}}>
                <Paper elevation={5} square>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{padding:'60px 0'}}>
                            <Grid container>
                                <form style={{width:'100%', padding:'0 15%'}} onSubmit={handleSubmit}>
                                    <Grid item xs={12} className="center_item" sx={{marginBottom:5}}>
                                        <span style={{color: theme.palette.primary.title}} className="title_lg">
                                            Login
                                        </span>
                                    </Grid>
                                    <Grid item xs={12} className="center_item" sx={{marginBottom:5}}>
                                        <TextField
                                            required
                                            id="username"
                                            label="Usuario"
                                            fullWidth
                                            sx={{color: theme.palette.primary.input}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="center_item" sx={{marginBottom:5}}>
                                        <FormControl sx={{  width: '100%' }} variant="outlined">
                                            <InputLabel htmlFor="password">Contraseña</InputLabel>
                                            <OutlinedInput
                                                id="password"
                                                type={showPassword ? 'text' : 'password'}
                                                required
                                                sx={{color: theme.palette.primary.input}}
                                                endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                                }
                                                label="Password"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} className="center_item">
                                        <Button variant="contained" className="btn_lg" type="submit">
                                            Log In
                                        </Button>
                                    </Grid>
                                </form>
                            </Grid>
                            {alerta && (
                                <div style={{paddingLeft: 20, paddingRight:20, marginTop:20}}>
                                    <Alert severity="error"
                                        sx={{display:'flex', justifyContent:'center'}}>
                                            Error al iniciar sesión
                                    </Alert>
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={0} sm={0} md={6} lg={6} xl={6} sx={{backgroundImage: `url(${img_lat})`}}>
                            <Box className="img_login"></Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </>
        

    )
}

export default Login;